import { useContext, useState } from 'react';
import { Context } from '../../DataStore';
import { useHistory } from 'react-router-dom';

import * as strings from '../../data/strings';
import * as constants from '../exports/constants';

import Message from './Message';

import '../../styles/common/Header.scss';

const GravityLogo = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/gravity-logo.svg`;
const DefaultAvatar = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/default-robot.svg`;
const SettingsIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/settings.svg`;
const SettingsSelectedIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/settings-selected.svg`;
const ProfileIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/profile.svg`;
const ProfileSelectedIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/profile-selected.svg`;
const LogoutIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/logout.svg`;
const LogoutSelectedIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/logout-selected.svg`;
const CloseIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/close.svg`;

const Header = () => {
  const { store, dispatch } = useContext(Context);
  const history = useHistory();

  const [menuOpen, setMenuOpen] = useState(false);
  const [menuHover, setMenuHover] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [activeItem, setActiveItem] = useState('');

  const endSession = () => {
    const languageArray = window.navigator.language.split('');
    const detectedLanguage = `${languageArray[0]}${languageArray[1]}`;
    const languages = ['en', 'zh', 'nl'];

    const storeCopy = {
      language: ((languageArray.length > 1 ? (languages.includes(detectedLanguage) ? detectedLanguage : 'en') : 'en')),
      profile: {},
      subscription: {
        active: false,
        type: null,
        tokens: null
      },
      communities: {
        data: [],
        fetched: false
      },
      groups: {
        data: [],
        fetched: false
      },
      spaces: {
        data: [],
        fetched: false
      },
      assets: {
        data: {},
        fetched: false
      },
      status: {
        community: {},
        group: {},
        space: {},
        asset: {},
        members: [],
        message: {
          type: null,
          text: null
        },
        modal: {
          active: false,
          action: null,
          type: null,
          store: null,
          thread: null,
          data: {
            title: null,
            description: null,
            inputTitle: null,
            placeholder: null,
            privacySetting: false
          }
        },
        recorder: {
          active: false,
          responding: {
            active: false,
            recording: false,
            started: false
          }
        },
        settings: {
          transcript: false,
          translate: false
        },
        menu: true
      },
      session: {},
      deeplink: {
        params: {},
        parsed: false,
        loaded: {
          communities: false,
          groups: false,
          spaces: false,
          assets: false,
        },
        premium: false
      },
      reload: {
        header: null,
        communities: null
      },
      marketing: {
        school: store.marketing.school
      }
    };
    dispatch({
      type: 'store',
      data: storeCopy,
    });

    constants.services.endSession();
    history.push("/login/");
  };

  const openMenu = () => {
    if (menuOpen) {
      setMenuActive(false);
    } else {
      setTimeout(() => {
        setMenuActive(true);
      }, 100);
    }
    setMenuOpen(!menuOpen);
  }

  const editProfile = () => {
    const statusCopy = {
      ...store.status,
      modal: {
        active: true,
        action: 'editProfile',
        data: {
          title: strings.default[store.language].Header.EditProfile,
          description: strings.default[store.language].Modal.ChangeDescription,
          inputTitle: strings.default[store.language].LoginRegister.FullName,
          value: store.profile.name,
          placeholder: strings.default[store.language].AssetsView.ResponseDescriptionPlaceholder,
          privacySetting: false
        }
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  }

  const goHome = () => {
    history.push('/');
    window.location.reload();
  }

  return (
    <div className="Header">
      {store.status.message.text !== null &&
        <Message />
      }

      <div className="content">
        <div className="logo">
          <button
            className="gravityHome"
            onClick={() => goHome()}>
            <img
              className="gravityLogo"
              src={GravityLogo}
              alt="Gravity logo" />
          </button>
        </div>
        <div className="options">
          {(store.profile && constants.utils.isObject(store.profile)) &&
            <div className="optionsWrapper">
              <div className="setting menuAvatar">
                <button
                  className={`settingsIcon${menuOpen ? ' open' : ''}`}
                  onMouseEnter={() => setMenuHover(true)}
                  onMouseLeave={() => setMenuHover(false)}
                  onClick={() => openMenu()}>
                  <div
                    className="avatar"
                    style={{ backgroundImage: `url(${store.profile.image !== undefined && store.profile.image !== '' ? store.profile.image : DefaultAvatar})` }} />
                  {(menuOpen || menuHover) &&
                    <>
                      <div className="avatarOverlay" />
                      <div
                        className="settingsImage"
                        style={{ backgroundImage: `url(${menuOpen ? (menuActive ? CloseIcon : SettingsSelectedIcon) : SettingsIcon})` }} />
                    </>
                  }

                </button>
              </div>
              {menuOpen &&
                <div className="contextMenu">
                  <button
                    className="control"
                    onMouseEnter={() => setActiveItem('menuProfile')}
                    onMouseLeave={() => setActiveItem('')}
                    onClick={() => editProfile()}>
                    <div className="menuIcon">
                      <img
                        src={activeItem === 'menuProfile' ? ProfileSelectedIcon : ProfileIcon}
                        className="menuImage"
                        alt={strings.default[store.language].Header.EditProfile} />
                    </div>
                    <div className="menuText">
                      {strings.default[store.language].Header.EditProfile}
                    </div>
                  </button>
                  <button
                    className="control"
                    onMouseEnter={() => setActiveItem('menuLogout')}
                    onMouseLeave={() => setActiveItem('')}
                    onClick={() => endSession()}>
                    <div className="menuIcon">
                      <img
                        src={activeItem === 'menuLogout' ? LogoutSelectedIcon : LogoutIcon}
                        className="menuImage"
                        alt={strings.default[store.language].Header.Logout} />
                    </div>
                    <div className="menuText">
                      {strings.default[store.language].Header.Logout}
                    </div>
                  </button>
                </div>
              }
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default Header;
