import ReactGA from 'react-ga4';

import { useContext } from 'react';
import { Context } from '../../DataStore';
import { useHistory } from 'react-router-dom';

import * as strings from '../../data/strings';

import '../../styles/marketing/CTASection.scss';

const CTASection = () => {
  const { store } = useContext(Context);
  const history = useHistory();
  
  const goRegister = () => {
    ReactGA.event({
      category: "Marketing",
      action: "Register - Footer"
    });
    history.push('/register/');
  }

  return (
    <div className="CTASection">
      <div className="ctaWrapper">
        <div className="ctaHeader">
          <div className="ctaText">
            {strings.default[store.language].Marketing.ReadyToSupercharge}
          </div>
          <div className="ctaSubtext">
            <img 
              className="gravityLogo"
              src={`${process.env.REACT_APP_CF_MKT_ENDPOINT}svg/gravity-logo-white.svg`}
              alt="Gravity"  />
          </div>
        </div>
        <div className="ctaEmail">
          <button 
            className="headerButton startHere"
            onClick={() => goRegister()}>
            {strings.default[store.language].Marketing.YesIAm}
          </button>
        </div>
        <div className="emailMessage">
          <a 
            className="email" 
            href="mailto:hello@usegravity.io"
            target="_blank" 
            rel="noreferrer">
              hello@usegravity.io
            </a>
        </div>
        <div className="ctaPrivacyTOS">
          <a 
            className="privacy" 
            href="/privacy/"
            target="_blank" 
            rel="noreferrer">
              {strings.default[store.language].Marketing.Privacy}
          </a>
          <span className="separator"> | </span>
          <a 
            className="tos" 
            href="/tos/"
            target="_blank" 
            rel="noreferrer">
              {strings.default[store.language].Marketing.TOS}
          </a>
        </div>
      </div>
    </div>
  );
}

export default CTASection;
