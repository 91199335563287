import ReactGA from 'react-ga4';

import { useContext, useState, useRef, useEffect, useCallback } from 'react';
import { Context } from '../../DataStore';
import { useHistory } from 'react-router-dom';

import * as strings from '../../data/strings';
import * as constants from '../exports/constants';

import '../../styles/dashboard/CreatorStudio.scss';

const CreatorStudio = (props) => {
	const { store, dispatch } = useContext(Context);
	const history = useHistory();

	const [hovering, setHovering] = useState('');

	const fetchedTokens = useRef(false);

	const sendMessage = useCallback((data) => {
		const statusCopy = {
			...store.status,
			message: {
				type: data.type,
				text: data.text
			}
		}
		dispatch({
			type: 'status',
			data: statusCopy
		});
	}, [dispatch, store.status]);

	const featureSet = {
		quick: [
			strings.default[store.language].Package[store.subscription.active ? '' : 'OneMinuteRecordingLimit']
		],
		magic: [
			strings.default[store.language].Package.AIAutoTranscription,
			strings.default[store.language].Package.AIKeywordsAndInsights,
			strings.default[store.language].Package.AIVoiceToneAnalysis
		],
		premium: [
			strings.default[store.language].Package.UnlimitedRecordingLimit,
			strings.default[store.language].Package.UnlimitedMagicAIPrompts,
			strings.default[store.language].Package.DocumentAttachments,
			strings.default[store.language].Package.TwentyFourSevenSupport
		]
	}

	const premiumModal = () => {
		ReactGA.event({
			category: 'Button',
			action: 'Click',
			label: 'Upgrade Premium - Creator Studio'
		});
		history.push('/dashboard/premium/');

		const statusCopy = {
			...store.status,
			modal: {
				active: true,
				action: 'premium',
				data: {
					title: store.subscription.active ? strings.default[store.language].Modal.YouArePremium : strings.default[store.language].Modal.PremiumTitle,
					description: store.subscription.active ? `${store.profile.name.split(' ')[0]}, ${strings.default[store.language].Modal.PremiumThankYou} ${strings.default[store.language].Modal.PremiumMember} (${strings.default[store.language].Modal[store.subscription.duration]})!` : strings.default[store.language].Modal.PremiumDescription
				}
			}
		}
		dispatch({
			type: 'status',
			data: statusCopy
		});
	}

	const createPrompt = (magic) => {
		ReactGA.event({
			category: 'Button',
			action: 'Click',
			label: `Prompt ${magic ? 'Magic' : 'Quick'} - Creator Studio`
		});

		if (magic && !store.subscription.active && store.subscription.tokens === 0) {
			premiumModal();
		} else {
			const statusCopy = {
				...store.status,
				modal: {
					active: true,
					action: 'create',
					type: 'space',
					store: 'spaces',
					thread: null,
					data: {
						title: strings.default[store.language].Dashboard[magic ? 'MagicAIPrompt' : 'QuickPrompt'],
						inputTitle: strings.default[store.language].Content.CreateSpaceName,
						privacySetting: false,
						magicAI: magic,
						creatorStudio: true
					}
				}
			}
			dispatch({
				type: 'status',
				data: statusCopy
			});
		}
	};

	const fetchToken = useCallback(async () => {
		try {
			let data = {
				uuid: store.profile.uuid,
				sessionId: store.session.sessionId
			}

			const url = `${constants.services.url.api}/payments/tokens/fetch/`;
			const response = await fetch(url, constants.services.config(data));
			const responseData = await response.json();

			if (response.ok) {
				setTimeout(() => {
					const subscriptionCopy = {
						...store.subscription,
						tokens: responseData.tokens
					}
					dispatch({
						type: 'subscription',
						data: subscriptionCopy
					});
				}, 0);
			} else {
				sendMessage(constants.strings.messages('error', 'network'));
			}
		} catch (error) {
			sendMessage(constants.strings.messages('error', 'network'));
		}
	}, [sendMessage, store.profile.uuid, store.session.sessionId, dispatch, store.subscription]);

	useEffect(() => {
		if (!store.subscription.active && !fetchedTokens.current) {
			fetchedTokens.current = true;
			fetchToken();
		}
	}, [store.subscription.active, fetchToken]);

	return (
		<div className={`CreatorStudio${props.mini !== undefined && !constants.utils.isMobile() ? ' mini' : ''}`}>
			<div className="welcomeColor">
				<div className="creatorBackground" />
				<div className="welcomeTextWrapper">
					<div className="welcomeWrapper">
						<div className="welcomeText">
							{strings.default[store.language].Dashboard.CreatorStudio}
						</div>
						<div className="studioType">
							{strings.default[store.language].Package[store.subscription.active ? 'Premium' : 'Basic']}
						</div>
						<div className="welcomeFunctions">
							<div className="welcomeButtonWrapper">
								<button
									className="welcomeButton quickButton"
									onMouseEnter={() => setHovering('quick')}
									onMouseLeave={() => setHovering('')}
									onClick={() => createPrompt(false)}>
									⚡ {strings.default[store.language].Dashboard.QuickPrompt}
								</button>
							</div>
							<div className="welcomeButtonWrapper">
								<button
									className="welcomeButton magicAIButton"
									onMouseEnter={() => setHovering('magic')}
									onMouseLeave={() => setHovering('')}
									onClick={() => createPrompt(true)}>
									🪄 {strings.default[store.language].Dashboard.MagicAIPrompt}
								</button>
								<div className="magicCount">
									<div className="magicNumber">
										{store.subscription.active ? strings.default[store.language].Package.Unlimited : `${store.subscription.tokens === null ? strings.default[store.language].Modal.Loading : `${store.subscription.tokens} ${strings.default[store.language].Dashboard.Left}`}`}
									</div>
								</div>
							</div>
							<div className="welcomeButtonWrapper">
								<button
									className="welcomeButton premiumButton"
									onMouseEnter={() => setHovering('premium')}
									onMouseLeave={() => setHovering('')}
									onClick={() => premiumModal()}>
									✨ {store.subscription.active ? 'Gravity' : strings.default[store.language].Dashboard.Unlock} <span className="premium">{strings.default[store.language].Package.Premium}</span>
								</button>
							</div>
						</div>
					</div>
					<div className={`functionDescription${hovering.length ? ' hovering' : ''}`}>
						<div className="descriptionWrapper">
							{hovering.length > 0 && (
								featureSet[hovering].map((feature, i) => (
									(feature === strings.default[store.language].Package.UnlimitedRecordingLimit ||
										feature === strings.default[store.language].Package.UnlimitedCommunities ||
										feature === strings.default[store.language].Package.UnlimitedFolders) ?
										(<div
											className="featureHover"
											key={`Feature${i}`}>
											<div className="unlimited">
												∞
											</div>
											<div className="feature">
												{feature}
											</div>
										</div>) :
										(feature !== undefined && feature.includes('AI')) ?
											(<div
												className="featureHover"
												key={`Feature${i}`}>
												<div className="ai">
													AI
												</div>
												<div className="feature">
													{feature.replace('AI ', '').replace('AI', '')}
												</div>
											</div>) :
											(feature === strings.default[store.language].Package.OneMinuteRecordingLimit) ?
												(<div
													className="featureHover"
													key={`Feature${i}`}>
													<div className="unlimited">
														➊
													</div>
													<div className="feature">
														{feature}
													</div>
												</div>) :
												(feature === strings.default[store.language].Package.DocumentAttachments) ?
													(<div
														className="featureHover"
														key={`Feature${i}`}>
														<div className="unlimited">
															✦
														</div>
														<div className="feature">
															{feature}
														</div>
													</div>) :
													(feature === strings.default[store.language].Package.TwentyFourSevenSupport) ?
														(<div
															className="featureHover"
															key={`Feature${i}`}>
															<div className="unlimited">
																✚
															</div>
															<div className="feature">
																{feature}
															</div>
														</div>) :
														<div
															className="featureHover"
															key={`Feature${i}`}>
															{feature}
														</div>
								)))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CreatorStudio;
