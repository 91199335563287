import { useState, useContext, useEffect } from 'react';
import { Context } from '../../DataStore';

import Loading from '../common/Loading';
import CreatorStudio from '../dashboard/CreatorStudio';
import Participant from '../common/Participant';
import Member from '../common/Member';

import * as strings from '../../data/strings';
import * as constants from '../exports/constants';

import '../../styles/composite/SpacesView.scss';

const DefaultAvatar = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/default-robot.svg`;
const DeleteIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/delete.svg`;
const CrownIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/crown.svg`;
const ChatBubbleBlack = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/chat-bubble-black.svg`;
const Check = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/check.svg`;
const CheckMarked = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/check-marked.svg`;
const noPromptsIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/no-prompts.svg`;
const VoiceIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/voice.svg`;
const DocumentIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/document.svg`;
const YoutubeIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/youtube.svg`;
const MembersViewIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/members-view.svg`;
const MembersCloseIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/members-close.svg`;
const GravityStar = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/gravity-star.svg`;

const NarchivesIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}png/naarchives-logo.png`;
const FigmaIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/figma.svg`;
const GdriveIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/gdrive.svg`;
const CanvaIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/canva.svg`;

const CalendarIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/calendar.svg`;

const SpacesView = (props) => {
  const { store, dispatch } = useContext(Context);
  const [loadingMembers, setLoadingMembers] = useState(false);
  const [viewingMembers, setViewingMembers] = useState(false);

  const fetchMembers = async () => {
    if (viewingMembers) {
      setViewingMembers(false);
    } else {
      if (store.status.members.length && store.status.members[0].cid === store.status.community.cid) {
        setViewingMembers(true);
      } else {
        setLoadingMembers(true);

        try {
          let data = {
            cid: store.status.community.cid,
            uuid: store.profile.uuid,
            sessionId: store.session.sessionId
          }

          const url = `${constants.services.url.api}/community/fetch/members/`;
          const response = await fetch(url, constants.services.config(data));
          const responseData = await response.json();

          if (responseData.status === 'Success') {
            setLoadingMembers(false);
            setViewingMembers(true);

            const statusCopy = {
              ...store.status,
              members: responseData.data
            }
            dispatch({
              type: 'status',
              data: statusCopy
            });
          }
        } catch (_) {
          setLoadingMembers(false);
          setViewingMembers(false);
        }
      }
    }
  }

  useEffect(() => {
    if (store.status.members.length && (store.status.members[0].cid !== store.status.community.cid)) {
      setViewingMembers(false);
      const statusCopy = {
        ...store.status,
        members: []
      }
      dispatch({
        type: 'status',
        data: statusCopy
      });
    }
  }, [store.status.community.cid, store.status.members, viewingMembers, dispatch, store.status]);

  return (
    <div className="SpacesView">
      <div className={`content${props.loadingDeeplink ? ' blurred' : ''}`}>

        <CreatorStudio mini />

        <div className="layoutBuffer"></div>

        <div className="membersButtonWrapper">
          <div className="membersButton">
            <button
              className="fetchMembers"
              disabled={loadingMembers}
              onClick={() => fetchMembers()}>
              {loadingMembers ?
                <Loading active="true" /> :
                <img
                  className={`membersIcon${viewingMembers ? ' close' : ''}`}
                  src={viewingMembers ? MembersCloseIcon : MembersViewIcon}
                  alt="Members" />
              }
              <div className="buttonText">
                {`${strings.default[store.language][viewingMembers ? 'Modal' : 'AssetsView'][viewingMembers ? 'Hide' : 'View']}${store.language !== 'zh' ? ' ' : ''}${strings.default[store.language].SpacesView.Members}`}
              </div>
            </button>
          </div>
          {(store.status.members.length > 0 && viewingMembers) &&
            <div className="membersView">
              <div className="membersCountWrapper">
                <div className="membersCount">
                  <img
                    src={GravityStar}
                    className="countShape"
                    alt="Gravity Star" />
                  <div className="count">
                    {store.status.members.length}
                  </div>
                  <div className="membersCommunity">
                    {store.status.community.title}
                  </div>
                </div>
              </div>
              {store.status.members.map((member, i) => (
                <Member 
                  key={`Member${i}`}
                  member={member}  />
              ))}
            </div>
          }
        </div>

        {store.spaces.data.length && (store.status.group.cid === store.status.community.cid) ?
          <div className={`data${viewingMembers ? ' membersActive' : ''}`}>
            {store.spaces.data.map((space, i) => (
              <div
                className="spaceWrapper"
                key={`SpaceWrapper${i}`}>
                <div className={`space${space.scheduled !== undefined && space.scheduled ? ' scheduled' : ''}`}>
                  <button
                    className="spaceData"
                    onClick={() => props.loadSpace(space)}>
                    {(space.scheduled !== undefined && space.scheduled) &&
                      <div className="scheduled">
                        <div className="scheduledText">
                          <img
                            className="calendarIcon"
                            src={CalendarIcon}
                            alt="Calendar" />
                          <span className="scheduledTextNode">
                            {`${strings.default[store.language].SpacesView.ScheduledFor} ${constants.utils.parseDate(space.creation)}`}
                          </span>
                        </div>
                      </div>
                    }
                    <div className="preview">
                      {space.type === 'voice' ?
                        <img
                          className="previewAuthor voice"
                          src={VoiceIcon}
                          alt="Voice" /> :
                        <div
                          className="previewVideo"
                          style={{ backgroundImage: `url("${constants.services.awsLocation(true)}/${space.aid}.gif")` }} />
                      }
                    </div>
                    <div className="spaceTitle">
                      <div className="creation">
                        <div className="dateWrapper">
                          <div className="date">
                            {constants.utils.parseDate(space.creation)}
                          </div>
                        </div>
                        {space.magic !== undefined && space.magic &&
                          <div className="magicAI">
                            AI
                          </div>
                        }
                        {space.files.length > 0 &&
                          <div className={`document${(space.metadata.youtube !== undefined && space.metadata.youtube !== '') ? ' andYoutube' : ''}`}>
                            <img
                              className='icon'
                              src={DocumentIcon}
                              alt="Document Attachment" />
                          </div>
                        }
                        {(space.metadata.youtube !== undefined && space.metadata.youtube !== '') &&
                          <div className="youtube">
                            <img
                              className='icon'
                              src={YoutubeIcon}
                              alt="YouTube Attachment" />
                          </div>
                        }
                        {(space.metadata.narchives !== undefined && space.metadata.narchives !== '') &&
                          <div className="narchives">
                            <img
                              className='icon'
                              src={NarchivesIcon}
                              alt="National Archives Attachment" />
                          </div>
                        }
                        {(space.metadata.figjam !== undefined && space.metadata.figjam !== '') &&
                          <div className="figjam">
                            <img
                              className='icon'
                              src={FigmaIcon}
                              alt="FigJam Attachment" />
                          </div>
                        }
                        {(space.metadata.gdrive !== undefined && space.metadata.gdrive !== '') &&
                          <div className="gdrive">
                            <img
                              className='icon'
                              src={GdriveIcon}
                              alt="Gdrive Attachment" />
                          </div>
                        }
                        {(space.metadata.canva !== undefined && space.metadata.canva !== '') &&
                          <div className="canva">
                            <img
                              className='icon'
                              src={CanvaIcon}
                              alt="Canva Attachment" />
                          </div>
                        }
                      </div>
                      {space.creator.uuid !== store.profile.uuid &&
                        <div className="check">
                          <img
                            className={`checkImage${space.participants.map((participant) => participant.uuid).indexOf(store.profile.uuid) > -1 ? ' complete' : ''}`}
                            src={space.participants.map((participant) => participant.uuid).indexOf(store.profile.uuid) > -1 ? CheckMarked : Check}
                            alt="Done check" />
                        </div>
                      }
                      <div className="title">
                        {space.metadata.title}
                      </div>
                    </div>
                    <div className="participants">
                      <div className="mainAvatarWrapper">
                        <img
                          className="author"
                          src={CrownIcon}
                          alt="Original poster" />
                        {space.creator.image === undefined || space.creator.image === '' ?
                          <Participant
                            i={0}
                            participant={{
                              name: space.creator.name
                            }}
                            asset /> :
                          <div
                            className="mainAvatar"
                            style={{ backgroundImage: `url(${space.creator !== undefined && space.creator.image !== '' ? constants.services.s3ToCloudfrontMedia(space.creator.image) : DefaultAvatar})` }} />
                        }
                        <div className="mainName">
                          <div className="createdName">
                            {space.creator.name}
                          </div>
                          <div className="createdBy">
                            <div className="createdText">
                              {space.creator.role}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="viewResponses">
                        <div className="responsesText">
                          {(space.participants.length === 0 ? 0 : space.participants.length - 1)}
                        </div>
                        <img
                          className="responsesIcon"
                          src={ChatBubbleBlack}
                          alt="Responses" />
                      </div>
                    </div>
                  </button>
                  {space.creator.uuid === store.profile.uuid &&
                    <div className="removeSpace">
                      <button
                        className="button"
                        onClick={(event) => props.removeSpace(space, event)}>
                        <img
                          className="delete"
                          src={DeleteIcon}
                          alt="Delete asset" />
                      </button>
                    </div>
                  }
                </div>
              </div>
            ))}
          </div> :
          <div className="emptySpace">
            <div className="noPrompts">
              <img
                className="noPromptsIcon"
                src={noPromptsIcon}
                alt="No discussions" />
            </div>
            {strings.default[store.language].Content[store.status.group.cid !== undefined && (store.status.community.cid !== store.status.group.cid) ? 'SelectAFolder' : 'NoPromptsYet']}
          </div>
        }
      </div>
    </div>
  );
}

export default SpacesView;
